import React from 'react';

const NotFoundPage = ({ transition }) => (
  <section style={ transition && transition.style } className="section">
    <div className="container">
      <div className="content">
        <h1 className="has-text-weight-bold thin-title">
          404 ¯\_(ツ)_/¯
        </h1>
        <p className="thin-title">Whoops. Nothing here it seems...</p>
      </div>
    </div>
  </section>
);

export default NotFoundPage;
